// noinspection JSNonASCIINames,NonAsciiCharacters

import {IArchivable, IIdentified, IUrl} from '../../../api/shared/common';
import {IEntityResource} from './entity-resource';
import {HttpClient} from '@angular/common/http';
import {downloadRef} from '../../util/util';
import {IDeletePayload, ISearchRequest, ISearchResponse, TWithId} from '../../../api/shared/search-api';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {EndpointUrl} from '../../util/http-converters';
import {camelCase, filter} from 'lodash';
import {OnDemandResourceLoaderService, ɵON_DEMAND_LOADERS} from './on-demand-resource-loader.service';
import {EnvironmentInjector, inject, runInInjectionContext} from '@angular/core';


const ɵENDPOINT = '$ENDPOINT';

export function Endpoint(endpoint: string, name?: string) {
  return function (constructor: Function) {
    constructor.prototype[ɵENDPOINT] = {endpoint, name};
  };
}

export function setInstanceEndpoint<T extends { endpoint: string; name?: string }>(instance: T): void {
  if ((instance as any)[ɵENDPOINT]) {
    instance.endpoint = (instance as any)[ɵENDPOINT].endpoint;
    instance.name = (instance as any)[ɵENDPOINT].name;
  }
}

export class EntityResourceBaseService<S extends {}, T extends S & IIdentified> implements IEntityResource<S, T> {
  endpoint!: string;
  name?: string;
  protected http = inject(HttpClient);
  private injector = inject(EnvironmentInjector);


  constructor() {
    console.log('$', this.constructor.name);
    setInstanceEndpoint(this);
    if (!this.name) {
      this.name = camelCase(this.endpoint.split('/').pop());
    }
    this.onDemandLoader.registerResourceLoader(this.onDemandCountName, () => this.getCount({}));
  }

  get onDemandLoader(): OnDemandResourceLoaderService {
    return inject(OnDemandResourceLoaderService);
  }

  searchEntities(searchRequest: ISearchRequest): Observable<ISearchResponse<T>> {
    return this.http.post<ISearchResponse<T>>(
      this.url('search'),
      searchRequest);
  }

  getCount(searchRequest: ISearchRequest): Observable<number> {
    return this.searchEntities({...searchRequest, ...{offset: 0, limit: 0}})
      .pipe(
        map((response) => response.total)
      );
  }

  getEntity(id: string): Observable<T> {
    return this.http.get<T>(
      this.url(':id', {id})
    );
  }

  createEntity(data: S): Observable<T> {
    return this.http.post<T>(
      this.url(),
      data).pipe(tap((p) => this.onEntityModified('create', p)));
  }

  updateEntity(id: string, data: S): Observable<T> {
    return this.http.put<T>(
      this.url(':id', {id}),
      data).pipe(tap((p) => this.onEntityModified('update', p)));
  }

  deleteEntities(ids: Array<string>): Observable<Array<T>> {
    return this.http.delete<Array<T>>(
      this.url(), {
        body: {
          ids,
        } satisfies IDeletePayload
      }).pipe(tap((p) => this.onEntityModified('delete', p)));
  }

  patchEntities(data: Array<TWithId<S>>): Observable<Array<T>> {
    return this.http.patch<Array<T>>(
      this.url(),
      data).pipe(tap((p) => this.onEntityModified('patch', p)));
  }

  exportEntities(searchRequest: ISearchRequest): void {
    downloadRef(this.http.post<IUrl>(
      this.url('export'),
      searchRequest));
  }

  get importUrl(): string {
    return this.url('import');
  }

  archiveEntities(entities: Array<TWithId<S>>): Observable<Array<T>> {
    const data = filter<IIdentified & IArchivable>(entities as any, (e) => !e.isArchived).map<IIdentified & IArchivable>((e) => ({
      id: e.id,
      isArchived: true
    }));
    return this.patchEntities(data as any);
  }

  distinctFetchHelper(
    req: ISearchRequest
  ): Observable<Array<T>> {
    return this.searchEntities({
      ...req, ...{
        isDistinct: true
      }
    }).pipe(
      map((response) => response.results)
    );
  }

  stringArrayFetchHelper(field: keyof T): Observable<Array<string>> {
    return this.distinctFetchHelper({sort: [{field: field as string}], fields: [field as string]})
      .pipe(
        map((entities) => entities.map((e) => e[field] as string))
      );
  }

  protected url(path?: string, params?: Record<any, string | number>): string {
    if (!path) {
      return this.endpoint;
    } else {
      return EndpointUrl.resolve(`${this.endpoint}/${path}`, params || {})
    }
  };

  protected onEntityModified(op: 'create' | 'update' | 'delete' | 'patch', data: any): void {
    this.resetOnDemandLoaders();
  }

  resetOnDemandLoaders(): void {
    runInInjectionContext(this.injector, () => {
      if ((this as any)[ɵON_DEMAND_LOADERS]) {
        this.onDemandLoader.reset((this as any)[ɵON_DEMAND_LOADERS]);
      }
      if (this.name) {
        this.onDemandLoader.reset(this.onDemandCountName)
      }
    });

  }

  get onDemandCountName(): string {
    return `${this.name}Count`;
  }
}


