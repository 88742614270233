import {Injector, Type} from '@angular/core';
import {ConfirmationService, Message, MessageService} from 'primeng/api';
import {Confirmation} from 'primeng/api/confirmation';

export class Alert {
  static injector: Injector;

  static message(message: Message): void {
    setTimeout(() => {
      Alert.injector.get(MessageService).add(message);
    });
  }

  static confirm(confirmation: Confirmation): void {
    setTimeout(() => {
      Alert.injector.get(ConfirmationService).confirm(confirmation);
    });
  }

  static asyncConfirm(confirmation: Confirmation): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      Alert.injector.get(ConfirmationService).confirm({
        ...confirmation,
        ...{accept: () => resolve(true), reject: () => resolve(false)}
      })
    });
  }

}
