import {HttpParams} from '@angular/common/http';
import {keys} from 'lodash';
import {dateToISOString} from './util';

export class HttpQueryParamsFromObject {
    public static toHttpParams(o: any, initialParams?: HttpParams): HttpParams {
        let params = initialParams || new HttpParams();
        if (o) {
            for (const p in o) {
                if (o.hasOwnProperty(p) && o[p] !== undefined) {
                    let val = o[p];
                    if (o[p] instanceof Date) { // TODO
                        val = dateToISOString(o[p] as Date);
                    }
                    params = params.append(p, val);
                }
            }
        }
        return params;
    }

    public static toHttpOptions<T>(o: T): { params: HttpParams } {
        return {
            params: HttpQueryParamsFromObject.toHttpParams(o)
        };
    }
}

export class FormDataFromObject {
  static toFormData(o: any): FormData {
    const formData = new FormData();
    if (o) {
      for (const p in o) {
        if (o.hasOwnProperty(p) && o[p] !== undefined) {
          const val = o[p];
          if (val instanceof File) {
            formData.append('file', val as File, (val as File).name);
            continue;
          }
          let converted: string;
          if (val instanceof Date) {
            converted = dateToISOString(val as Date);
          } else {
            converted = val.toString();
          }
          formData.append(p, converted);
        }
      }
    }
    return formData;
  }
}

export class EndpointUrl {
  public static resolve(url: string, params: Record<any, string | number>): string {
    keys(params)
      .forEach((param) => {
        const re = new RegExp('\:' + param);
        if (re.test(url)) {
          url = url.replace(re, encodeURIComponent(params[param].toString()));
        }
      });
    return url;
  }
}

