import {isObject, reduce, startCase} from 'lodash';
import {formatDate} from '@angular/common';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'options',
    standalone: true
})
export class OptionsPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  transform(value: any, options?: {useAll?: boolean; startCase?: boolean}): any {
    if (value == null) {
      return value;
    }
    let opts;
    if (value instanceof Array) {
      opts = value.map((v: any) => {
        if (v instanceof Date) {
          return {label: formatDate(v as Date, 'MM/dd/yyyy', this.locale), value: v};
        }
        return isObject(v) ? v : {label: options?.startCase ? startCase(v?.toString()) : v?.toString(), value: v};
      });
    } else {  // object like {name: 'Name', ...}
      opts = reduce(value, (acc, val, key) => {
        acc.push({
          label: val?.label || val?.toString(),
          value: key
        } as never);
        return acc;
      }, []);
    }
    if (!!options?.useAll) {
      opts.unshift({label: 'All', value: null});
    }
    return opts;
  }
}

