import {DestroyRef, ExistingProvider, forwardRef, inject, InjectionToken, SimpleChanges, Type} from '@angular/core';
import {DatePipe} from '@angular/common';
import {combineLatest, filter, Observable, of, Subscription, switchMap} from 'rxjs';
import {AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {forEach, isArray, isDate, isEmpty, isEqual, isObject, some, toNumber} from 'lodash';
import {HttpClient} from '@angular/common/http';
import {IAttachment, IUrl} from '../../api/shared/common';
import {TDictionary} from '../types';
import {
  ActivatedRoute,
  DefaultUrlSerializer,
  NavigationStart,
  PRIMARY_OUTLET,
  Router,
  UrlSegment
} from '@angular/router';
import {Alert} from './alert';
import {DateTime} from 'luxon';

export const HTTP_URL_REGEX = /https?:\/\/(www\.)?(?<domain>[-a-zA-Z0-9@:%._\+~#=]{2,256})\.([a-z]{2,6}){1}/;


export function getFirstDayDate(date = new Date()): Date {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function decodeJwtPayload<X = {}>(token: string): X {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}

export function unixTime(date: Date = new Date()): number {
  return Math.floor(date.getTime() / 1000);
}


export function dateToISOString(date: Date, fmt = 'yyyy-MM-dd'): string {
  if (date.getMinutes() === 0 && date.getSeconds() === 0 && date.getMilliseconds() === 0
    || date.getMinutes() === 59 && date.getSeconds() === 59 && date.getMilliseconds() === 999) {
    return new DatePipe('en-US').transform(date, fmt)!;
  }
  return date.toISOString();
}

export function currentDate(): Date {
  const d = new Date();
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
}

export function stringToColor(str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    color += ('00' + ((hash >> (i * 8)) & 0xFF).toString(16)).substr(-2);
  }
  return color.toUpperCase();
}

export function hexToRgba(hex: string, alpha = 1): string {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
}

export function clearObject(obj: any): any {
  for (const p in obj) {
    delete obj[p];
  }
  return obj;
}


export const DATE_REGEXPS = [
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.?\d*))(?:Z|(\+|-)([\d|:]*))?$/,
  /^(\d{4})-(\d{2})-(\d{2})$/
];


export function tryConvertToDate(value: any): Date | null {
  let result: Date | null = null;
  if (!value || typeof value !== 'string') {
    return result;
  }
  forEach(DATE_REGEXPS, (regEx) => {
    const match = value.match(regEx);
    if (match) {
      result = DateTime.fromISO(match[0]).toJSDate();
      return false;
    } else {
      return true;
    }
  });
  return result;
}

export function deepDifference(data: any, other: any, checkOnly = false): any {
  if (isArray(data)) {
    let diff = [];
    if (!isEqual(data, other)) {
      diff = data;
    }
    return checkOnly ? !isEmpty(diff) : diff;
  }
  const diff: any = {};
  Object.keys(data).forEach((key: string) => {
    if (!isObject(data[key])) {
      if (!isEqual(data[key], other[key])) {
        diff[key] = data[key];
        if (checkOnly) {
          return diff;
        }
      }
    } else {
      if (!isEqual(data[key], other[key])) {
        if (isArray(data[key]) || isDate(data[key])) {
          diff[key] = data[key];
          if (checkOnly) {
            return diff;
          }
        } else {
          diff[key] = deepDifference(data[key], other[key]);
        }
      }
    }
  });
  return checkOnly ? !isEmpty(diff) : diff;
}

export async function copyToClipboard(text: string): Promise<void> {
  await navigator.clipboard.writeText(text);
  Alert.message({severity: 'success', detail: 'Copied to clipboard'});
}


export function downloadRef(rest: Observable<IUrl>, fileName?: string): void {
  rest.subscribe((response) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = response.url;
    downloadLink.setAttribute('download', fileName ?? '');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  });
}


export function downloadAttachment(http: HttpClient, attachment: IAttachment): void {
  http.get(new URL(attachment.url).pathname, {responseType: 'blob'})
    .subscribe((response) => {
      const blob = new Blob([response]);
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.setAttribute('download', attachment.name);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    })
}

export function forwardProvider<T>(token: InjectionToken<T>, type: Type<any>): ExistingProvider {
  return {
    provide: token,
    useExisting: forwardRef(() => type),
    multi: true
  }
}

export function valueAccessorProvider(type: Type<any>): Array<ExistingProvider> {
  return [
    forwardProvider(NG_VALUE_ACCESSOR, type),
    forwardProvider(NG_VALIDATORS, type)
  ];
}

export function inheritanceProvider(fromBase: any, toClass: Type<any>): ExistingProvider {
  return {provide: fromBase, useExisting: forwardRef(() => toClass)};
}


export function getUrlDomain(url: string): string | undefined {
  return (url || '').match(HTTP_URL_REGEX)?.groups?.['domain'];
}


export function murmurHash(str: string, seed = 100): number {
  let i = 0;
  let l = str.length;
  let h = seed ^ l;
  let k: number;

  while (l >= 4) {
    k =
      ((str.charCodeAt(i) & 0xff)) |
      ((str.charCodeAt(++i) & 0xff) << 8) |
      ((str.charCodeAt(++i) & 0xff) << 16) |
      ((str.charCodeAt(++i) & 0xff) << 24);

    k = (((k & 0xffff) * 0x5bd1e995) + ((((k >>> 16) * 0x5bd1e995) & 0xffff) << 16));
    k ^= k >>> 24;
    k = (((k & 0xffff) * 0x5bd1e995) + ((((k >>> 16) * 0x5bd1e995) & 0xffff) << 16));

    h = (((h & 0xffff) * 0x5bd1e995) + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16)) ^ k;

    l -= 4;
    ++i;
  }

  switch (l) {
    case 3:
      h ^= (str.charCodeAt(i + 2) & 0xff) << 16;
      break;
    case 2:
      h ^= (str.charCodeAt(i + 1) & 0xff) << 8;
      break;
    case 1:
      h ^= (str.charCodeAt(i) & 0xff);
      h = (((h & 0xffff) * 0x5bd1e995) + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16));
      break;
  }

  h ^= h >>> 13;
  h = (((h & 0xffff) * 0x5bd1e995) + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16));
  h ^= h >>> 15;

  return h >>> 0;
}

export function plural(word: string | undefined): string {
  const plural: { [key: string]: string } = {
    '(quiz)$': "$1zes",
    '^(ox)$': "$1en",
    '([m|l])ouse$': "$1ice",
    '(matr|vert|ind)ix|ex$': "$1ices",
    '(x|ch|ss|sh)$': "$1es",
    '([^aeiouy]|qu)y$': "$1ies",
    '(hive)$': "$1s",
    '(?:([^f])fe|([lr])f)$': "$1$2ves",
    '(shea|lea|loa|thie)f$': "$1ves",
    'sis$': "ses",
    '([ti])um$': "$1a",
    '(tomat|potat|ech|her|vet)o$': "$1oes",
    '(bu)s$': "$1ses",
    '(alias)$': "$1es",
    '(octop)us$': "$1i",
    '(ax|test)is$': "$1es",
    '(us)$': "$1es",
    '([^s]+)$': "$1s"
  }
  const irregular: { [key: string]: string } = {
    'move': 'moves',
    'foot': 'feet',
    'goose': 'geese',
    'sex': 'sexes',
    'child': 'children',
    'man': 'men',
    'tooth': 'teeth',
    'person': 'people'
  }
  const uncountable: string[] = [
    'sheep',
    'fish',
    'deer',
    'moose',
    'series',
    'species',
    'money',
    'rice',
    'information',
    'equipment',
    'bison',
    'cod',
    'offspring',
    'pike',
    'salmon',
    'shrimp',
    'swine',
    'trout',
    'aircraft',
    'hovercraft',
    'spacecraft',
    'sugar',
    'tuna',
    'you',
    'wood'
  ]
  if (!word) {
    return '';
  }
  if (uncountable.indexOf(word.toLowerCase()) >= 0) {
    return word
  }
  for (const w in irregular) {
    const pattern = new RegExp(`${ w }$`, 'i')
    const replace = irregular[w]
    if (pattern.test(word)) {
      return word.replace(pattern, replace)
    }
  }
  for (const reg in plural) {
    const pattern = new RegExp(reg, 'i')
    if (pattern.test(word)) {
      return word.replace(pattern, plural[reg])
    }
  }
  return word
}


export function removeControlErrors(control: AbstractControl, errorKeys: Array<string>): void {
  if (!control || !errorKeys || errorKeys.length === 0) {
    return;
  }

  const remainingErrors = errorKeys.reduce((errors, key) => {
    delete errors[key];
    return errors;
  }, {...control.errors});

  control.setErrors(remainingErrors);

  if (Object.keys(control.errors || {}).length === 0) {
    control.setErrors(null);
  }
}


export function addControlErrors(control: AbstractControl, errorKeys: Array<string>): void {
  if (!control || !errorKeys) {
    return;
  }
  control.setErrors({
    ...control.errors,
    ...errorKeys.reduce((errors, key) => ({...errors, ...{[key]: true}}), {})
  });
}

export function addOrRemoveControlErrors(control: AbstractControl, invalid: boolean, errorKeys: Array<string>): void {
  if (invalid) {
    addControlErrors(control, errorKeys);
  } else {
    removeControlErrors(control, errorKeys);
  }
}

export function onChangesAfterFirst<T>(changes: SimpleChanges, checkChanges: Array<keyof T>, onChanged?: (changes: SimpleChanges) => void): boolean {
  const changed = some(checkChanges, (ch) => !!changes[ch as string] && !changes[ch as string].firstChange);
  if (!!onChanged && changed) {
    onChanged(changes);
  }
  return changed;
}


export interface IRouteParamPropDescriptor {
  isQuery?: boolean;
  paramName?: string;
  type?: 'string' | 'boolean' | 'number' | 'date' | 'json' | 'base64' | ((value: any) => any);
  defaultValue?: any;
  isArray?: boolean;
}

export function routeParamsToObject<T extends TDictionary<T>>(props: {
  [prop in keyof T]: IRouteParamPropDescriptor | null
}): Observable<T> {
  const activatedRoute = inject(ActivatedRoute);

  const convert = (
    prop: NonNullable<{ [prop in keyof T]: IRouteParamPropDescriptor | null }[Extract<keyof T, string>]>,
    paramValue: any,
    defaultValue: any
  ) => {
    let resultPropValue: any;
    if (typeof prop.type === 'function') {
      resultPropValue = prop.type(paramValue) || defaultValue;
    } else if (prop.type === 'boolean') {
      resultPropValue = (paramValue === 'true');
    } else if (prop.type === 'number') {
      if (!paramValue) {
        resultPropValue = defaultValue;
      } else {
        const n = toNumber(paramValue);
        resultPropValue = !isNaN(n) ? n : defaultValue;
      }
    } else if (prop.type === 'date') {
      resultPropValue = DateTime.fromISO(paramValue).toJSDate();
      resultPropValue = isFinite(resultPropValue) ? resultPropValue : defaultValue;
    } else if (prop.type === 'json' || prop.type === 'base64') {
      let val = paramValue;
      if (prop.type === 'base64') {
        try {
          val = atob(paramValue)
        } catch (e) {
          val = null;
        }
      }
      resultPropValue = parseJson(val);
      if (resultPropValue === undefined) {
        resultPropValue = defaultValue;
      }
    } else {
      resultPropValue = paramValue || defaultValue;
    }
    return resultPropValue;
  }
  return combineLatest([activatedRoute.params, activatedRoute.queryParams])
    .pipe(
      switchMap(([pathParams, queryParams]) => {
        const result: T = {} as T;
        for (const p in props) {
          const prop = props[p] || {};
          const paramName = prop.paramName ?? p;
          const paramValue = prop.isQuery ? queryParams[paramName] : pathParams[paramName] || queryParams[paramName];
          let resultPropValue: any;
          if (prop.isArray) {
            if (!isArray(paramValue)) {
              resultPropValue = prop.defaultValue;
            } else {
              resultPropValue = paramValue.map((v) => convert(prop, v, undefined)).filter((v) => v !== undefined);
            }
          } else {
            if (!isArray(paramValue)) {
              resultPropValue = convert(prop, paramValue, prop.defaultValue)
            } else {
              resultPropValue = prop.defaultValue;
            }
          }
          result[p] = resultPropValue;
        }
        return of(result);
      })
    );
}

export function getUrlWithoutLastSegmentParams(urlSegments: Array<UrlSegment>): string {
  return '/' + urlSegments.map((s, i) => i === urlSegments.length - 1 ? s.path : s.toString()).join('/')
}

export function parseUrl(url: string): {
  primarySegments: Array<UrlSegment>;
  queryParams: { [name: string]: string };
  fragment: string | null;
  urlWithoutLastSegmentParams: string;
} {
  const serializer = new DefaultUrlSerializer();
  const toParse = url // ?? location.pathname + (location.search ?? '') + (location.hash ?? '');
  const tree = serializer.parse(toParse);
  const primarySegments = tree.root.children[PRIMARY_OUTLET].segments;
  const result = {
    primarySegments,
    queryParams: tree.queryParams,
    fragment: tree.fragment,
    urlWithoutLastSegmentParams: getUrlWithoutLastSegmentParams(primarySegments)
  };
  return result;
}


export function isNavigationImperativeAsync(): Observable<boolean> {
  const router = inject(Router);
  return router.events.pipe(
    filter((event): event is NavigationStart => event instanceof NavigationStart),
    switchMap((event): Observable<boolean> => {
      return of(!(event.navigationTrigger === 'popstate' || event.navigationTrigger === 'imperative' && event.restoredState != null));
    })
  );
}

export function isNavigationImperative(): boolean {
  const nav = inject(Router).getCurrentNavigation();
  return !(nav?.trigger === 'popstate' || nav?.trigger === 'imperative' && !nav?.previousNavigation);
}

export function getStorageKeys(prefix: string): Array<string> {
  return Object.keys(localStorage).filter(key => key.startsWith(prefix));
}

export function clearStorageKeys(prefix: string): void {
  getStorageKeys(prefix).forEach(key => localStorage.removeItem(key));
}


export function parseJson(json: string): any {
  let result: any = undefined
  if (!json) {
    return result;
  }
  const reviver = (key: any, value: any) => {
    const date = tryConvertToDate(value);
    if (date) {
      return date;
    }
    return value;
  };
  try {
    result = JSON.parse(json, reviver);
  } catch (e) {
  }
  return result;
}

export function getStorageItem<T>(key: string): T | null | undefined {
  if (!key) {
    return undefined;
  }
  const stateString = localStorage.getItem(key);
  if (stateString === undefined) {
    return undefined;
  }
  if (stateString === null) {
    return null;
  }
  return parseJson(stateString);
}

export function setStorageItem(key: string, state: any): void {
  if (key) {
    localStorage.setItem(key, JSON.stringify(state));
  }
}

export function isAllNullOrUndefined(input: any): boolean {
  if (input === null || input === undefined) {
    return true;
  }

  if (Array.isArray(input)) {
    return input.length === 0;
  }

  if (typeof input === 'object') {
    return Object.values(input).every(value => isAllNullOrUndefined(value));
  }

  return false;
}

export interface ISubscription {
  add(...subscription: Array<Subscription>): ISubscription;
}
export function subscriptions(): ISubscription {
  const destroyRef = inject(DestroyRef);
  const subscriptions: Array<Subscription> = [];
  const sub: ISubscription = {
    add(...subscription) {
      subscriptions.push(...subscription);
      return sub;
    }
  }
  const unsubscribe = () =>  {
    while (subscriptions.length) {
      subscriptions.shift()!.unsubscribe();
    }
  }

  destroyRef.onDestroy(() => {
    unsubscribe();
  });
  return sub;
}
