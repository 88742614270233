import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {MenuItem, PrimeTemplate} from 'primeng/api';
import {clearStorageKeys, isNavigationImperative, parseUrl, plural, subscriptions} from '../../util/util';
import {NavigationEnd, Router} from '@angular/router';
import {OnDemandResourceLoaderService} from '../../services/resources/on-demand-resource-loader.service';
import {Alert} from '../../util/alert';
import {RippleModule} from 'primeng/ripple';
import {ButtonModule} from 'primeng/button';
import {AsyncPipe, NgTemplateOutlet} from '@angular/common';
import {MenubarModule} from 'primeng/menubar';
import {
  CommonPageService,
  ICommonPageEvent,
  NewEntityEvent,
  PageHeaderTemplateEvent,
  PageTitleEvent
} from './common-page.service';
import {filter} from 'rxjs';

export interface ICommonPageHeaderTemplates {
  topLeft?: TemplateRef<any>;
  topCenter?: TemplateRef<any>;
  topRight?: TemplateRef<any>;
  bottom?: TemplateRef<any>;
}

export const COMMON_PAGE_HEADER_TEMPLATES: { [name: string]: keyof ICommonPageHeaderTemplates } = {
  'topHeader.left': 'topLeft',
  'topHeader.center': 'topCenter',
  'topHeader.right': 'topRight',
  'bottomHeader': 'bottom'
};

@Component({
  selector: 'app-common-page',
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4 flex align-items-center">
            @if (showTitle) {
              <div class="mr-2">
                <div class="mt-page-title">{{ pluralEntityName() }}</div>
                @if (onDemandCountMethod && onDemandLoader.isResourceRegistered(onDemandCountMethod)) {
                  <div
                    class="text-gray-700 text-sm">{{ $any(onDemandLoader.observe(onDemandCountMethod) | async) }} {{ pluralEntityName() }}
                  </div>
                }
              </div>
            }
            @if (tplHeader.topLeft) {
              <ng-container *ngTemplateOutlet="tplHeader.topLeft"></ng-container>
            }
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
            @if (navMenu) {
              <p-menubar class="mt-page-menu-bar" [model]="navMenu"></p-menubar>
            }
            @if (tplHeader.topCenter) {
              <ng-container *ngTemplateOutlet="tplHeader.topCenter"></ng-container>
            }
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            @if (tplHeader.topRight) {
              <ng-container *ngTemplateOutlet="tplHeader.topRight"></ng-container>
            }
            @if (showNewEntityButton) {
              <button pButton pRipple [label]="'New ' + entityName"
                      class="ml-2"
                      icon="pi pi-plus" (click)="notifyEntity()"></button>
            }
          </div>
        </div>
      </div>
      @if (tplHeader.bottom) {
        <div class="mt-page-header-content">
          <div class="flex align-items-center justify-content-between">
            <ng-container *ngTemplateOutlet="tplHeader.bottom"></ng-container>
          </div>
        </div>
      }
    </div>
    <div class="mt-3">
      <router-outlet></router-outlet>
      <ng-container *ngTemplateOutlet="tplContent!"></ng-container>
    </div>
  `,
  standalone: true,
  providers: [CommonPageService],
  imports: [NgTemplateOutlet, ButtonModule, RippleModule, AsyncPipe, MenubarModule]
})
export class CommonPageComponent implements AfterContentInit, OnChanges, OnInit {
  @Input() entityName?: string;
  @Input() onDemandCountMethod?: string;
  @Input() showTitle = true;
  @Input() navMenu?: Array<MenuItem>;
  @Input() showNewEntityButton = true;
  @Input() stateKey?: string;
  @Output() onPageInit: EventEmitter<void> = new EventEmitter<void>();
  @Output() onNewEntity: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClearStorage: EventEmitter<void> = new EventEmitter<void>();
  @ContentChildren(PrimeTemplate) protected templates!: QueryList<PrimeTemplate>;
  tplContent?: TemplateRef<any>;
  tplHeader: ICommonPageHeaderTemplates = {};
  lastPathSegment?: string;
  protected needClearStorage = false;

  constructor(public router: Router,
              public onDemandLoader: OnDemandResourceLoaderService,
              private cdr: ChangeDetectorRef,
              public commonPageService: CommonPageService) {
    subscriptions().add(
      commonPageService.eventsOfType(PageTitleEvent).subscribe((e) => {
        this.entityName = e.entityName;
        this.onDemandCountMethod = e.onDemandCountMethod;
        this.cdr.detectChanges();
      }),
      this.commonPageService.eventsOfType(PageHeaderTemplateEvent).subscribe((e) => {
        this.tplHeader = e.headerTemplates;
        this.cdr.detectChanges();
      }),
      this.router.events
        .pipe(
          filter((event: any) => event instanceof NavigationEnd)
        )
        .subscribe(({urlAfterRedirects}: NavigationEnd) => {
          this.lastPathSegment = [...parseUrl(urlAfterRedirects).primarySegments].pop()?.path;
        })
    );
    if (isNavigationImperative()) {
      this.needClearStorage = true;
    }
  }


  dispatchEvent(event: ICommonPageEvent): void {
    this.commonPageService.dispatchEvent(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.onDemandCountMethod) {
      this.cdr.detectChanges();
    }
  }

  ngOnInit() {
    if (this.needClearStorage) {
      this.clearStorage();
    }
    this.onPageInit.emit();
  }

  ngAfterContentInit(): void {
    this.templates.forEach((tpl) => {
      if (tpl.getType() === 'content') {
        this.tplContent = tpl.template;
      } else {
        if (COMMON_PAGE_HEADER_TEMPLATES[tpl.getType()]) {
          this.tplHeader[COMMON_PAGE_HEADER_TEMPLATES[tpl.getType()]] = tpl.template;
        }
      }
    });
  }

  pluralEntityName(): string {
    if (this.entityName && this.entityName.startsWith('#')) {
      return this.entityName.slice(1);
    }
    return plural(this.entityName!);
  }

  notifyEntity(): void {
    this.onNewEntity.emit()
    this.commonPageService.dispatchEvent(new NewEntityEvent());
  }

  clearStorage(): void {
    if (this.stateKey) {
      clearStorageKeys(this.stateKey);
    }
    this.onClearStorage.emit();
    this.commonPageService.notifyClearStorage();
  }

  tbd(): void {
    Alert.message({
      severity: 'info',
      summary: '',
      detail: 'Not implemented yet'
    });
  }
}
