import {Directive} from '@angular/core';
import {AbstractControl, ControlValueAccessor, ValidationErrors, Validator} from '@angular/forms';

@Directive()
export abstract class AbstractControlValueAccessor<T> implements ControlValueAccessor, Validator {
  value: T | null | undefined;
  isDisabled = false;
  onModelChange: (val: any) => void = () => {};
  onModelTouched: () => void = () => {};
  onValidatorChange: () => void = () => {};


  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

}

