import {inject, Injectable} from '@angular/core';
import {filter, Observable, ReplaySubject, Subject} from 'rxjs';
import {TConstructor} from '../../types';
import {ICommonPageHeaderTemplates} from './common-page.component';

export interface ICommonPageEvent {
}

export class NewEntityEvent implements ICommonPageEvent {
}

export class PageTitleEvent implements ICommonPageEvent {
  constructor(public entityName: string, public onDemandCountMethod?: string) {
  }
}

export class PageHeaderTemplateEvent implements ICommonPageEvent {
  constructor(public headerTemplates: ICommonPageHeaderTemplates) {
  }
}


@Injectable()
export class CommonPageService {

  private _events = new Subject<ICommonPageEvent>();
  private _clearStorage = new ReplaySubject<void>();

  get events(): Observable<ICommonPageEvent> {
    return this._events;
  }

  eventsOfType<T extends ICommonPageEvent>(eventType: TConstructor<T>): Observable<T> {
    return this.events
      .pipe(filter((e): e is T => e instanceof eventType));
  }

  dispatchEvent(event: ICommonPageEvent): void {
    this._events.next(event);
  }

  get clearStorage(): Observable<void> {
    return this._clearStorage;
  }

  notifyClearStorage(): void {
    this._clearStorage.next();
  }

}

export function notifyCommonPageTitle(entityName: string, onDemandCountMethod?: string): void {
  inject(CommonPageService).dispatchEvent(new PageTitleEvent(entityName, onDemandCountMethod));
}

